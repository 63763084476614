<template>
  <UToggle
    v-model="model"
    :ui="{ container: { base: 'duration-300' } }"
    class="duration-300"
  />
</template>

<script setup lang="ts">
const model = defineModel({ type: Boolean })
</script>
